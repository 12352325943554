:root {
  /* text */
  --text-heading-font-family: "Silka", sans-serif;
  --text-text-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --text-size-mega-font-size: 41px;
  --text-size-mega-line-height: 1.2;
  --text-size-xl-font-size: 35px;
  --text-size-xl-line-height: 1.2;
  --text-size-lg-font-size: 28px;
  --text-size-lg-line-height: 1.2;
  --text-size-md-font-size: 20px;
  --text-size-md-line-height: 1.2;
  --text-size-sm-font-size: 16px;
  --text-size-sm-line-height: 1.2;
  --text-size-xs-font-size: 14px;
  --text-size-xs-line-height: 1.2;
  --text-size-micro-font-size: 12px;
  --text-size-micro-line-height: 1.2;
  --text-size-nano-font-size: 10px;
  --text-size-nano-line-height: 1.2;

  --text-weight-bold: 700;
  --text-weight-semibold: 600;
  --text-weight-regular: 400;
  --text-weight-light: 274;

  /* colors */
  --color-black-10: #e6e5e8;
  --color-black-20: #cdccd2;
  --color-black-30: #b4b3bc;
  --color-black-40: #9b99a5;
  --color-black-50: #82808e;
  --color-black-60: #6a6678;
  --color-black-70: #514c61;
  --color-black-80: #38334b;
  --color-black-90: #1f1a35;
  --color-black-core: #06001e;

  --color-white-10: #fbfbfb;
  --color-white-20: #fbfbfb;
  --color-white-30: #fcfcfc;
  --color-white-40: #fcfcfc;
  --color-white-50: #fdfdfd;
  --color-white-60: #fdfdfd;
  --color-white-70: #fefefe;
  --color-white-80: #fefefe;
  --color-white-90: #ffffff;
  --color-white-core: #ffffff;

  --color-banana-10: #fff5e8;
  --color-banana-20: #ffecd1;
  --color-banana-30: #ffe2ba;
  --color-banana-40: #ffd8a3;
  --color-banana-50: #ffce8b;
  --color-banana-60: #ffc574;
  --color-banana-70: #ffbb5d;
  --color-banana-core: #ff9e18;

  --color-eggplant-10: #eeecf7;
  --color-eggplant-20: #ded9ef;
  --color-eggplant-30: #cdc6e7;
  --color-eggplant-40: #bdb3df;
  --color-eggplant-50: #aca0d8;
  --color-eggplant-60: #9b8dd0;
  --color-eggplant-70: #8b7ac8;
  --color-eggplant-core: #5941b0;

  --color-grapefruit-10: #ffeeee;
  --color-grapefruit-20: #ffdede;
  --color-grapefruit-30: #ffcdce;
  --color-grapefruit-40: #ffbdbd;
  --color-grapefruit-50: #ffacad;
  --color-grapefruit-60: #ff9b9c;
  --color-grapefruit-70: #ff8b8b;
  --color-grapefruit-core: #ff595a;

  --color-raspberry-10: #f7ebf1;
  --color-raspberry-20: #eed8e3;
  --color-raspberry-30: #e6c5d5;
  --color-raspberry-40: #deb1c7;
  --color-raspberry-50: #d59eb9;
  --color-raspberry-60: #cd8aaa;
  --color-raspberry-70: #c5769c;
  --color-raspberry-core: #ac3c72;

  --color-spinach-10: #1c8e46;
  --color-spinach-20: #359b5a;
  --color-spinach-30: #4fa86f;
  --color-spinach-40: #68b483;
  --color-spinach-50: #81c098;
  --color-spinach-60: #9acdad;
  --color-spinach-70: #b3dac1;
  --color-spinach-core: #038231;

  --color-gradients-primary: linear-gradient(
    155.82deg,
    #5941b0 0%,
    #ff595a 100%
  );

  /* old styles, not used, to be thrown away  */
  --primary-darker: #000022;
  --primary-dark: #201255;
  --primary-regular: #534588;
  --primary-light: #8678bb;
  --primary-lighter: #b9abee;
  --primary-xlighter: #ecdeff;

  --secondary-darker: #770102;
  --secondary-dark: #cc2627;
  --secondary-regular: #ff595a;
  --secondary-light: rgba(255, 89, 90, 0.6);
  --secondary-lighter: rgba(255, 89, 90, 0.2);

  --neutral-darkest: #a7abb2;
  --neutral-darker: #dadee5;
  --neutral-dark: #f5f6f7;
  --neutral-regular: #ffffff;
  --neutral-link: #0000ff;

  --success-green-regular: #00947e;

  --utility-text-regular: #000000;
  --utility-text-lighter: #414550;
  --utility-text-lightest: #747880;

  --utility-background-tint: rgba(0, 0, 34, 0.7);
  --utility-background-tint2: rgba(0, 0, 34, 0.5);

  --shadow-elevation-1: 0px 2px 7px 0px #00000033;

  --header-height: 72px;
  --footer-height: 82px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10 !important;
}

.space-detail__description p {
  margin-bottom: 12px;
}

.space-detail__description ul {
  padding-left: 24px;
}