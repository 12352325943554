.preferred-text {
  background: linear-gradient(144deg, #5941b0 -16.53%, #ff595a 152.61%);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
}

.preferred-spotlight-wrapper {
  background: linear-gradient(144deg, #5941b0 -16.53%, #ff595a 152.61%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}
