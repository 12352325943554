.dropdown-root {
  position: relative;
}

.dropdown-content-wrapper {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: calc(100% + 8px);
}
