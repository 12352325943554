.column-grid {
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1040px;
}

.column-grid-row {
  position: relative;
  box-sizing: border-box;
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  justify-content: flex-start;
  flex-flow: row wrap;
}

.column-grid-col {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
}

.column-grid-col-1 {
  width: 8.3333%;
  max-width: 8.3333%;
  flex-basis: 8.3333%;
}

.column-grid-col-2 {
  width: 16.6667%;
  max-width: 16.6667%;
  flex-basis: 16.6667%;
}

.column-grid-col-3 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.column-grid-col-4 {
  width: 33.3333%;
  max-width: 33.3333%;
  flex-basis: 33.3333%;
}

.column-grid-col-5 {
  width: 41.6667%;
  max-width: 41.6667%;
  flex-basis: 41.6667%;
}

.column-grid-col-6 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.column-grid-col-7 {
  width: 58.3333%;
  max-width: 58.3333%;
  flex-basis: 58.3333%;
}

.column-grid-col-8 {
  width: 66.6667%;
  max-width: 66.6667%;
  flex-basis: 66.6667%;
}

.column-grid-col-9 {
  width: 75%;
  max-width: 75%;
  flex-basis: 75%;
}

.column-grid-col-10 {
  width: 83.3333%;
  max-width: 83.3333%;
  flex-basis: 83.3333%;
}

.column-grid-col-11 {
  width: 91.6667%;
  max-width: 91.6667%;
  flex-basis: 91.6667%;
}

.column-grid-col-12 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}
