.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.character {
  width: 40px;
  height: 60px !important;
  border: 1px solid #e6e5e8;
  font-weight: 700;
  font-size: 42px;
  color: #1f1a35;
  display: flex;
  align-items: center;
  justify-content: center;
}

.character:hover {
  border: 1px solid #514d61;
}

.character:focus {
  border: 1px solid #5941b0;
}

.character:nth-child(3) {
  margin-right: 12px;
}

@media only screen and (min-width: 576px) {
  .character-sm-up {
    width: 60px;
    height: 80px !important;
  }

  .character:nth-child(3) {
    margin-right: 24px;
  }
}

.character--inactive {
  border: 1px solid #e6e5e8;
  background-color: white;
}

.character--selected {
  border: 1px solid #5941b0;
  background-color: white;
}

.character--error {
  border: 1px solid #ff595a;
}
