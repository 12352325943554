.page-container {
  box-sizing: border-box;
}

.page-container-offset-footer-and-header {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.page-container-offset-footer {
  min-height: calc(100vh - var(--footer-height));
}

.page-container-offset-header {
  min-height: calc(100vh - var(--header-height));
}

.page-container-offset-header-no-scroll {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  max-height: calc(100vh - var(--header-height) - var(--footer-height) + 28px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.page-container-no-offset {
  min-height: 100vh;
}

.new-bg {
  background-color: #f5f5f5;
}
