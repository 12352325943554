.heading {
  font-family: var(--text-heading-font-family);
  font-weight: normal;
  padding: 0px;
  margin: 0px;
}

.heading-size-mega {
  font-size: var(--text-size-mega-font-size);
  line-height: var(--text-size-mega-line-height);
}

.heading-size-xl {
  font-size: var(--text-size-xl-font-size);
  line-height: var(--text-size-xl-line-height);
}

.heading-size-lg {
  font-size: var(--text-size-lg-font-size);
  line-height: var(--text-size-lg-line-height);
}

.heading-size-md {
  font-size: var(--text-size-md-font-size);
  line-height: var(--text-size-md-line-height);
}

.heading-size-sm {
  font-size: var(--text-size-sm-font-size);
  line-height: var(--text-size-sm-line-height);
}

.heading-size-xs {
  font-size: var(--text-size-xs-font-size);
  line-height: var(--text-size-xs-line-height);
}

.heading-size-micro {
  font-size: var(--text-size-micro-font-size);
  line-height: var(--text-size-micro-line-height);
}

.heading-color-black-10 {
  color: var(--color-black-10);
}
.heading-color-black-20 {
  color: var(--color-black-20);
}
.heading-color-black-30 {
  color: var(--color-black-30);
}
.heading-color-black-40 {
  color: var(--color-black-40);
}
.heading-color-black-50 {
  color: var(--color-black-50);
}
.heading-color-black-60 {
  color: var(--color-black-60);
}
.heading-color-black-70 {
  color: var(--color-black-70);
}
.heading-color-black-80 {
  color: var(--color-black-80);
}
.heading-color-black-90 {
  color: var(--color-black-90);
}
.heading-color-black-core {
  color: var(--color-black-core);
}

.heading-color-white-10 {
  color: var(--color-white-10);
}
.heading-color-white-20 {
  color: var(--color-white-20);
}
.heading-color-white-30 {
  color: var(--color-white-30);
}
.heading-color-white-40 {
  color: var(--color-white-40);
}
.heading-color-white-50 {
  color: var(--color-white-50);
}
.heading-color-white-60 {
  color: var(--color-white-60);
}
.heading-color-white-70 {
  color: var(--color-white-70);
}
.heading-color-white-80 {
  color: var(--color-white-80);
}
.heading-color-white-90 {
  color: var(--color-white-90);
}
.heading-color-white-core {
  color: var(--color-white-core);
}

.heading-color-banana-10 {
  color: var(--color-banana-10);
}
.heading-color-banana-20 {
  color: var(--color-banana-20);
}
.heading-color-banana-30 {
  color: var(--color-banana-30);
}
.heading-color-banana-40 {
  color: var(--color-banana-40);
}
.heading-color-banana-50 {
  color: var(--color-banana-50);
}
.heading-color-banana-60 {
  color: var(--color-banana-60);
}
.heading-color-banana-70 {
  color: var(--color-banana-70);
}
.heading-color-banana-core {
  color: var(--color-banana-core);
}

.heading-color-eggplant-10 {
  color: var(--color-eggplant-10);
}
.heading-color-eggplant-20 {
  color: var(--color-eggplant-20);
}
.heading-color-eggplant-30 {
  color: var(--color-eggplant-30);
}
.heading-color-eggplant-40 {
  color: var(--color-eggplant-40);
}
.heading-color-eggplant-50 {
  color: var(--color-eggplant-50);
}
.heading-color-eggplant-60 {
  color: var(--color-eggplant-60);
}
.heading-color-eggplant-70 {
  color: var(--color-eggplant-70);
}
.heading-color-eggplant-core {
  color: var(--color-eggplant-core);
}

.heading-color-grapefruit-10 {
  color: var(--color-grapefruit-10);
}
.heading-color-grapefruit-20 {
  color: var(--color-grapefruit-20);
}
.heading-color-grapefruit-30 {
  color: var(--color-grapefruit-30);
}
.heading-color-grapefruit-40 {
  color: var(--color-grapefruit-40);
}
.heading-color-grapefruit-50 {
  color: var(--color-grapefruit-50);
}
.heading-color-grapefruit-60 {
  color: var(--color-grapefruit-60);
}
.heading-color-grapefruit-70 {
  color: var(--color-grapefruit-70);
}
.heading-color-grapefruit-core {
  color: var(--color-grapefruit-core);
}

.heading-color-raspberry-10 {
  color: var(--color-raspberry-10);
}
.heading-color-raspberry-20 {
  color: var(--color-raspberry-20);
}
.heading-color-raspberry-30 {
  color: var(--color-raspberry-30);
}
.heading-color-raspberry-40 {
  color: var(--color-raspberry-40);
}
.heading-color-raspberry-50 {
  color: var(--color-raspberry-50);
}
.heading-color-raspberry-60 {
  color: var(--color-raspberry-60);
}
.heading-color-raspberry-70 {
  color: var(--color-raspberry-70);
}
.heading-color-raspberry-core {
  color: var(--color-raspberry-core);
}

.heading-weight-bold {
  font-weight: var(--text-weight-bold);
}
.heading-weight-semibold {
  font-weight: var(--text-weight-semibold);
}
.heading-weight-regular {
  font-weight: var(--text-weight-regular);
}
.heading-weight-light {
  font-weight: var(--text-weight-light);
}

.heading-align-left {
  text-align: left;
}

.heading-align-right {
  text-align: right;
}

.heading-align-center {
  text-align: center;
}
