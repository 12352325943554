input[data-text-input="class"] {
  font-family: var(--text-text-font-family);
  font-size: var(--text-size-xs-font-size);
  line-height: var(--text-size-xs-line-height);
}

@media only screen and (min-width: 576px) {
  input[data-text-input="class"] {
    font-family: var(--text-text-font-family);
    font-size: var(--text-size-xs-font-size);
    line-height: var(--text-size-xs-line-height);
  }
}
