.preferred-partner-banner {
  border-radius: 4px;
  background: linear-gradient(
      144deg,
      rgba(89, 65, 176, 0.12) -16.53%,
      rgba(255, 89, 90, 0.12) 152.61%
    ),
    #fafafa;
  padding: 16px;
  display: flex;
}

.preferred-text {
  background: linear-gradient(144deg, #5941b0 -16.53%, #ff595a 152.61%);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
}
