.office-space-status-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #33dd22;
  border: 1px solid #ffffff;
  bottom: 0;
  right: 0;
  position: absolute;
  box-sizing: border-box;
}

.office-space-booking-row {
  box-sizing: border-box;
  padding: 8px;
}

.office-space-booking-row-hoverable:hover div {
  opacity: 1 !important;
}

.office-space-booking-row-hoverable:hover {
  background-color: #f5f6f7;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .office-space-booking-row {
    padding: 8px;
  }
}

.office-space-description {
  font-family: Silka, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.office-space-description p {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 21px;
}

.office-space-description h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 16px;
  font-size: 31px;
  font-weight: 700;
  line-height: 37.2px;
}

.office-space-description h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 16px;
  font-size: 28px;
  font-weight: 600;
  line-height: 33.5px;
}

.office-space-description h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.office-space-description h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.76px;
}

.office-space-description a {
  color: rgb(0, 0, 0);
  font-weight: 500;
  text-decoration-line: underline;
  text-transform: none;
  line-height: 21px;
}

.office-space-description code {
  background-color: rgba(175, 184, 193, 0.2);
}

.office-space-description pre {
  background-color: rgba(175, 184, 193, 0.2);
  overflow: auto;
}
