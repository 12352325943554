.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e6e5e8;
}

.header-inner-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 2;
  background-color: #fff;
}

.header-navigation-menu {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-right-menu {
  display: none;
}

@media only screen and (min-width: 768px) {
  .header-navigation-menu {
    display: flex;
  }

  .header-right-menu {
    display: flex;
  }
}

.header-navigation-menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-family: var(--text-text-font-family);
  font-size: var(--text-size-xs-font-size);
  line-height: var(--text-size-xs-line-height);
  color: var(--primary-darker);
  height: 40px;
  width: max-content;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.header-navigation-menu-button:hover {
  color: var(--primary-regular);
}

.header-navigation-menu-button:focus {
  color: var(--primary-light);
}

.header-navigation-menu-button:active {
  color: var(--primary-regular);
  text-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
}

.header-navigation-menu-button-selected {
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: var(--primary-regular);
  color: var(--primary-regular);
  font-weight: 600;
}
