.people-person-container {
  width: 32px;
  height: 32px;
  position: relative;
  box-sizing: border-box;
}

.people-status-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #33dd22;
  border: 1px solid #ffffff;
  bottom: 0;
  right: 0;
  position: absolute;
  box-sizing: border-box;
}

.people-more-people-container {
  width: 32px;
  height: 32px;
  border: 1px solid #dadee5;
  border-radius: 50%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
