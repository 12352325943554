.reporting-budget-card-desktop {
  background: linear-gradient(155.82deg, #5941b0 0%, #ff595a 100%);
  border-radius: 6px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.reporting-budget-card-mobile {
  background: linear-gradient(155.82deg, #5941b0 0%, #ff595a 100%);
  border-radius: 6px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}