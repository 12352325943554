.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.row > * {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.col-xs-1 {
  flex-basis: 8.333%;
}
.col-xs-2 {
  flex-basis: 16.666%;
}
.col-xs-3 {
  flex-basis: 25%;
}
.col-xs-4 {
  flex-basis: 33.333%;
}
.col-xs-5 {
  flex-basis: 41.666%;
}
.col-xs-6 {
  flex-basis: 50%;
}
.col-xs-7 {
  flex-basis: 58.333%;
}
.col-xs-8 {
  flex-basis: 66.666%;
}
.col-xs-9 {
  flex-basis: 75%;
}
.col-xs-10 {
  flex-basis: 83.333%;
}
.col-xs-11 {
  flex-basis: 91.666%;
}
.col-xs-12 {
  flex-basis: 100%;
}

@media screen and (min-width: 576px) {
  .col-sm-1 {
    flex-basis: 8.333%;
  }
  .col-sm-2 {
    flex-basis: 16.666%;
  }
  .col-sm-3 {
    flex-basis: 25%;
  }
  .col-sm-4 {
    flex-basis: 33.333%;
  }
  .col-sm-5 {
    flex-basis: 41.666%;
  }
  .col-sm-6 {
    flex-basis: 50%;
  }
  .col-sm-7 {
    flex-basis: 58.333%;
  }
  .col-sm-8 {
    flex-basis: 66.666%;
  }
  .col-sm-9 {
    flex-basis: 75%;
  }
  .col-sm-10 {
    flex-basis: 83.333%;
  }
  .col-sm-11 {
    flex-basis: 91.666%;
  }
  .col-sm-12 {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 768px) {
  .col-md-1 {
    flex-basis: 8.333%;
  }
  .col-md-2 {
    flex-basis: 16.666%;
  }
  .col-md-3 {
    flex-basis: 25%;
  }
  .col-md-4 {
    flex-basis: 33.333%;
  }
  .col-md-5 {
    flex-basis: 41.666%;
  }
  .col-md-6 {
    flex-basis: 50%;
  }
  .col-md-7 {
    flex-basis: 58.333%;
  }
  .col-md-8 {
    flex-basis: 66.666%;
  }
  .col-md-9 {
    flex-basis: 75%;
  }
  .col-md-10 {
    flex-basis: 83.333%;
  }
  .col-md-11 {
    flex-basis: 91.666%;
  }
  .col-md-12 {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 992px) {
  .col-lg-1 {
    flex-basis: 8.333%;
  }
  .col-lg-2 {
    flex-basis: 16.666%;
  }
  .col-lg-3 {
    flex-basis: 25%;
  }
  .col-lg-4 {
    flex-basis: 33.333%;
  }
  .col-lg-5 {
    flex-basis: 41.666%;
  }
  .col-lg-6 {
    flex-basis: 50%;
  }
  .col-lg-7 {
    flex-basis: 58.333%;
  }
  .col-lg-8 {
    flex-basis: 66.666%;
  }
  .col-lg-9 {
    flex-basis: 75%;
  }
  .col-lg-10 {
    flex-basis: 83.333%;
  }
  .col-lg-11 {
    flex-basis: 91.666%;
  }
  .col-lg-12 {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .col-xl-1 {
    flex-basis: 8.333%;
  }
  .col-xl-2 {
    flex-basis: 16.666%;
  }
  .col-xl-3 {
    flex-basis: 25%;
  }
  .col-xl-4 {
    flex-basis: 33.333%;
  }
  .col-xl-5 {
    flex-basis: 41.666%;
  }
  .col-xl-6 {
    flex-basis: 50%;
  }
  .col-xl-7 {
    flex-basis: 58.333%;
  }
  .col-xl-8 {
    flex-basis: 66.666%;
  }
  .col-xl-9 {
    flex-basis: 75%;
  }
  .col-xl-10 {
    flex-basis: 83.333%;
  }
  .col-xl-11 {
    flex-basis: 91.666%;
  }
  .col-xl-12 {
    flex-basis: 100%;
  }
}

.hidden-xs {
  display: none;
}

@media screen and (min-width: 576px) {
  .hidden-sm {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .hidden-md {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .hidden-lg {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .hidden-xl {
    display: none;
  }
}

.visible-xs {
  display: block;
}
.visible-sm {
  display: none;
}
.visible-md {
  display: none;
}
.visible-lg {
  display: none;
}
.visible-xl {
  display: none;
}

@media screen and (min-width: 576px) {
  .visible-sm {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .visible-md {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .visible-lg {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .visible-xl {
    display: block;
  }
}
