.space-details-page_v2--desktop-background {
  background-color: #fafafa;
}

.space-details-sticky-panel {
  position: sticky;
  top: 0px;
  padding-top: 0px;
}

.space-details-bottom-bar {
  border: 1px solid rgba(218, 222, 229, 0.5);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.space-details-layout {
  border-radius: 5px;
  width: 50px;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  text-align: center;
  padding-top: 6px;
}

.space-details-layout-img {
  height: 35px;
  width: 35px;
}

.space-details-description {
  font-family: var(--text-text-font-family);
  font-size: 14px;
  line-height: 21px;
}

.space-details-layout-wrapper {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  flex-wrap: nowrap;
  padding-bottom: 8px;
}

.space-details-layout-item {
  border-radius: 15px;
  min-width: 160px;
  height: 148px;
  border-width: 1px;
  border-style: solid;
  margin-right: 7px;
  box-sizing: border-box;
}

.space-details-layout-text {
  padding: 0 0 0 16px;
}

.space-details-layout-item-img {
  height: 100px;
  width: 100px;
}

@media screen and (min-width: 768px) {
  .space-details-layout-wrapper {
    flex-wrap: wrap;
    overflow-x: hidden;
  }

  .space-details-layout-item {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.offsite-space-details-directions-html p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: var(--text-text-font-family);
  font-size: var(--text-size-xs-font-size);
  line-height: var(--text-size-xs-line-height);
}
