.spaces-checkout-left-column {
  flex: 1;
  padding-right: 32px;
}

.spaces-checkout-right-column {
  flex-basis: 44%;
}

.spaces-checkout-sticky-panel {
  position: sticky;
  top: 0px;
  padding-top: 24px;
}
