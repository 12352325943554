.carousel .control-dots {
  bottom: 0px;
}

.carousel .control-dots .dot {
  background: rgba(6, 0, 30, 0.2);
  box-shadow: none;
  width: 4px;
  margin: 0 4px;
  width: 8px;
  height: 8px;
}

.carousel .control-dots .dot.selected {
  background: rgba(6, 0, 30, 0.7);
}

.carousel .carousel-status {
  display: none;
}

.carousel .axis-vertical {
  display: none;
}