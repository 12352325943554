.map-marker {
  z-index: 0;
}

.map-marker--selected {
  z-index: 1;
}

/* Reset native popup */
.mapboxgl-popup-content {
  padding: 0px;
  border-radius: 7px;
  border: none;
  box-shadow: none;
}
