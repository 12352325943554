.tooltip {
  position: relative;
  z-index: 1;
}

.tooltip:hover .tooltip-content {
  display: block;
}

.tooltip-content {
  display: none;
  position: absolute;
}

.tooltip-content-bottom-left {
  right: 0;
  top: 30px;
}

.tooltip-content-top-right {
  left: 0;
  bottom: 35px;
}
