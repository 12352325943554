.chips-input__wrapper {
  min-height: 152px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 8px;
  width: 100%;
}

.chips-input__chip {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  background: #eeecf7;
  border: 1px solid #e6e5e8;
  border-radius: 100px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.chips-input__chip > .text {
  word-break: break-all;
}

.chips-input__chip-delete-btn {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  margin-left: 6px;
}

.chips-input__form {
  display: inline-block;
  flex-grow: 1;
}

.chips-input__input {
  border: none;
  outline: none;
  font-size: 14px;
  width: 95%;
  padding: 7px;
}
