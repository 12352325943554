.picker-root {
  box-sizing: border-box;
  position: relative;
  padding-left: 12px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
}

.picker-root-icon {
  padding-left: 32px;
}

.picker-icon-wrapper {
  position: absolute;
  display: flex;
  top: 0;
  left: 8px;
  bottom: 1px;
  align-items: center;
}

.picker-arrow {
  position: absolute;
  right: 8px;
}
.picker-arrow-has-value {
  top: -4px;
}

.picker-select {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  border: none;
  appearance: none;
  font-size: 16px;
  cursor: pointer;
}

.picker-disabled {
  cursor: not-allowed;
}
