.bottom-bar-navigation-content {
  display: flex;
  position: fixed;
  bottom: 0;
  height: 72px;
  background: white;
  right: 0;
  left: 0;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  z-index: 3;
  padding-bottom: 8px;
}

.bottom-bar-navigation-button {
  text-decoration: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--text-text-font-family);
  color: var(--utility-text-lightest);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

.bottom-bar-navigation-button-selected,
.bottom-bar-navigation-button-selected svg {
  font-weight: 600;
  color: var(--primary-regular);
  fill: var(--primary-regular) !important;
}

@media only screen and (min-width: 768px) {
  .bottom-bar-navigation-content {
    display: none;
  }
}
